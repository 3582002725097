<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/shop' }">门店管理</el-breadcrumb-item>
      <el-breadcrumb-item>结算记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <el-button
          type="primary"
          size="small"
          style="background: #4781d9; border: 0"
          class="btn-item"
          @click="chainGoodExport"
        >
          <span>导出</span>
          <i class="iconfont icon-daochu" style="font-size:12px"></i>
        </el-button>
      <el-form
        ref="form"
        :model="settForm"
        label-width="80px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="门店名称">
          <el-input
            v-model="settForm.shopName"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="结算时间" style="margin-left: 150px">
          <el-date-picker :editable="false" @change="change" type="month"  v-model="settForm.settTime" style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          type="index"
          :header-cell-style="{ background: '#CD0550', color: '#fff' }"
        >
          <el-table-column prop="id" label="ID"/>
          <el-table-column prop="shop.name" label="门店名称"/>
          <el-table-column prop="settlement_time" label="结算时间"/>
          <el-table-column prop="order_amount" label="订单金额"/>
          <el-table-column prop="last_amount" label="已结退款金额"/>
          <el-table-column prop="settle_amount" label="总结算金额"/>
          <el-table-column prop="platform_amount" label="平台服务费"/>
          <el-table-column prop="created_at" label="创建时间"> 
            <template slot-scope="scope">
                {{ scope.row.created_at | ctime }}
            </template>
          </el-table-column>
        </el-table>
        <div class="page-num">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="prev,pager,next, total,sizes,jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
  </div>
</template>

<script>
import {getShopSettleList_api} from "@/api/shop.js"
import moment from "moment"
import axios from "axios"
import { httpUrl } from "@/utils/http.js"
export default {
  data() {
    return {
      settForm: {
        shopName: "",
        settTime: "",
      },
      currentPage: 1,
      pageSizes: [10, 20,50],
      pageSize: 10,
      total: 0,
      tableData:[],
      num:0,
      active:1,
      filename:"结算记录"
    }
  },
  filters: {
    ctime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  methods: {
    change(e){
      this.settForm.settTime=e.getFullYear()+'-'+((e.getMonth()+1)<10?'0'+(e.getMonth()+1):(e.getMonth()+1))
    },
    //重置
    emptySearch() {
      this.settForm = {
        shopName: "",
        settTime: ""
      }
      this.currentPage=1
       this.getShopSettleList()
    },
    //搜索
    search() {
      this.currentPage=1
       this.getShopSettleList()
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize=e
       this.getShopSettleList()
    },
     //切换页
    handleCurrentChange(e) {
      this.currentPage=e
       this.getShopSettleList()
    },
    //显示操作页面
    operation(row) {
      if(this.num==row.num){
        this.num=0
      }else{
        this.num=row.num
      }
    },
    //获取结算
    async getShopSettleList(){
      let res=await getShopSettleList_api({
        shop_name:this.settForm.shopName,
        settlement_time:this.settForm.settTime,
        page:this.currentPage,
        limit:this.pageSize
      })
      this.tableData=res.data.data.list
      this.total=res.data.data.total_count
    },
     //导出
    chainGoodExport() {
      const loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      axios
        .get(
          `${httpUrl}api/v1/shop/settle/export?shop_name=${this.settForm.shopName}&settlement_time=${this.settForm.settTime}`,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.filename; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
            loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
         this.$message.error('导出失败,请重试!');
          loading.close()
        })
    }
  },
  created(){
    this.getShopSettleList()
  }
}
</script>

<style lang="less" scoped>
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    // background: rgba(246, 219, 229, 0.2);
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
</style>